module.exports = String.raw`Helpers {
  Node = TextNode*
  TextNode = AnyExceptPlus<openControl>
  openControl = empty

  empty = /* nothing */
  anyExcept<lit> = (~ lit any)
  anyExceptStar<lit> = (~ lit any)*
  anyExceptPlus<lit> = (~ lit any)+
  AnyExcept<lit> = (~ lit any)
  AnyExceptPlus<lit> = (~ lit any)+
  AnyExceptStar<lit> = (~ lit any)*
  identifierCharacter = alnum | "_" | "-"

  orderedListOf<a, b, sep> =
    | nonemptyOrderedListOf<a, b, sep>
    | emptyListOf<a, sep>
  nonemptyOrderedListOf<a, b, sep> =
    | nonemptyListOf<b, sep>
    | nonemptyOrderedListOfBoth<a, b, sep>
    | nonemptyListOf<a, sep>
  nonemptyOrderedListOfBoth<a, b, sep> =
    nonemptyListOf<a, sep> (sep nonemptyListOf<b, sep>)

  controls = "\u{007F}".."\u{009F}"
  noncharacters = "\u{FDD0}".."\u{FDEF}"
  newline = "\r"? "\n"
}

Liquid <: Helpers {
  Node := (liquidNode | TextNode)*
  openControl := "{{" | "{%"

  liquidNode =
    | liquidRawTag
    | liquidDrop
    | liquidTagClose
    | liquidTagOpen
    | liquidTag
    | liquidInlineComment

  liquidTag =
    | liquidTagAssign
    | liquidTagCycle
    | liquidTagDecrement
    | liquidTagEcho
    | liquidTagElsif
    | liquidTagInclude
    | liquidTagIncrement
    | liquidTagLayout
    | liquidTagLiquid
    | liquidTagRender
    | liquidTagSection
    | liquidTagWhen
    | liquidTagBaseCase

  liquidTagOpen =
    | liquidTagOpenCase
    | liquidTagOpenCapture
    | liquidTagOpenForm
    | liquidTagOpenFor
    | liquidTagOpenTablerow
    | liquidTagOpenIf
    | liquidTagOpenPaginate
    | liquidTagOpenUnless
    | liquidTagOpenBaseCase
  liquidTagClose = "{%" "-"? space* "end" blockName space* tagMarkup "-"? "%}"

  // These two are the same but transformed differently
  liquidTagRule<name, markup> =
    "{%" "-"? space* (name ~identifierCharacter) space* markup "-"? "%}"
  liquidTagOpenRule<name, markup> =
    "{%" "-"? space* (name ~identifierCharacter) space* markup "-"? "%}"

  liquidTagBaseCase = liquidTagRule<liquidTagName, tagMarkup>

  liquidTagEcho = liquidTagRule<"echo", liquidTagEchoMarkup>
  liquidTagEchoMarkup = liquidVariable

  liquidTagAssign = liquidTagRule<"assign", liquidTagAssignMarkup>
  liquidTagAssignMarkup = variableSegment space* "=" space* liquidVariable

  liquidTagCycle = liquidTagRule<"cycle", liquidTagCycleMarkup>
  liquidTagCycleMarkup = (liquidExpression ":")? space* nonemptyListOf<liquidExpression, argumentSeparator> space*

  liquidTagIncrement = liquidTagRule<"increment", variableSegmentAsLookupMarkup>
  liquidTagDecrement = liquidTagRule<"decrement", variableSegmentAsLookupMarkup>
  liquidTagOpenCapture = liquidTagOpenRule<"capture", variableSegmentAsLookupMarkup>
  variableSegmentAsLookupMarkup = variableSegmentAsLookup space*

  liquidTagSection = liquidTagRule<"section", liquidTagSectionMarkup>
  liquidTagSectionMarkup = liquidString space*

  liquidTagLayout = liquidTagRule<"layout", liquidTagLayoutMarkup>
  liquidTagLayoutMarkup = liquidExpression space*

  // We'll black hole the statement and switch parser in the cst builder
  // We do this because it's technically the same grammar (with minor redefinitions)
  // and it would be a huge chore and maintenance hell to rewrite all the rules with
  // hspace = " " | "\t"
  //
  // The alternative is that this grammar parses the {% liquid tagMarkup %} as its own string,
  // and then we switch to the LiquidStatement grammar that
  // redefines liquidTagOpenRule, liquidTagRule, and space.
  liquidTagLiquid = liquidTagRule<"liquid", liquidTagLiquidMarkup>
  liquidTagLiquidMarkup = tagMarkup

  liquidTagInclude = liquidTagRule<"include", liquidTagRenderMarkup>
  liquidTagRender = liquidTagRule<"render", liquidTagRenderMarkup>
  liquidTagRenderMarkup =
    snippetExpression renderVariableExpression? renderAliasExpression? (argumentSeparatorOptionalComma tagArguments) space*
  snippetExpression = liquidString | variableSegmentAsLookup
  renderVariableExpression = space+ ("for" | "with") space+ liquidExpression
  renderAliasExpression = space+ "as" space+ variableSegment

  liquidTagOpenBaseCase = liquidTagOpenRule<blockName, tagMarkup>

  liquidTagOpenForm = liquidTagOpenRule<"form", liquidTagOpenFormMarkup>
  liquidTagOpenFormMarkup = arguments space*

  liquidTagOpenFor = liquidTagOpenRule<"for", liquidTagOpenForMarkup>
  liquidTagOpenForMarkup =
    variableSegment space* "in" space* liquidExpression
    (space* "reversed")? argumentSeparatorOptionalComma
    tagArguments space*

  // It's the same, the difference is support for different named arguments
  liquidTagOpenTablerow = liquidTagOpenRule<"tablerow", liquidTagOpenForMarkup>

  liquidTagOpenCase = liquidTagOpenRule<"case", liquidTagOpenCaseMarkup>
  liquidTagOpenCaseMarkup = liquidExpression space*

  liquidTagWhen = liquidTagRule<"when", liquidTagWhenMarkup>
  liquidTagWhenMarkup = nonemptyListOf<liquidExpression, whenMarkupSep> space*
  whenMarkupSep = space* ("," | "or" ~identifier) space*

  liquidTagOpenIf = liquidTagOpenRule<"if", liquidTagOpenConditionalMarkup>
  liquidTagOpenUnless = liquidTagOpenRule<"unless", liquidTagOpenConditionalMarkup>
  liquidTagElsif = liquidTagRule<"elsif", liquidTagOpenConditionalMarkup>

  liquidTagOpenConditionalMarkup = nonemptyListOf<condition, conditionSeparator> space*
  conditionSeparator = &logicalOperator
  condition = logicalOperator? space* (comparison | liquidExpression) space*
  logicalOperator = ("and" | "or") ~identifier
  comparison = liquidExpression space* comparator space* liquidExpression
  comparator =
    ( "=="
    | "!="
    | ">="
    | "<="
    | ">"
    | "<")
    | ("contains" ~identifier)

  liquidTagOpenPaginate = liquidTagOpenRule<"paginate", liquidTagOpenPaginateMarkup>
  liquidTagOpenPaginateMarkup =
    liquidExpression space+ "by" space+ liquidExpression (argumentSeparatorOptionalComma tagArguments)? space*

  liquidDrop = "{{" "-"? space* liquidDropCases "-"? "}}"
  liquidDropCases = liquidVariable | liquidDropBaseCase
  liquidDropBaseCase = anyExceptStar<("-}}" | "}}")>
  liquidInlineComment = "{%" "-"? space* "#" space? tagMarkup "-"? "%}"

  liquidRawTag =
    | liquidRawTagImpl<"raw">
    | liquidRawTagImpl<"comment">
    | liquidRawTagImpl<"javascript">
    | liquidRawTagImpl<"schema">
    | liquidRawTagImpl<"style">
  liquidRawTagImpl<name> =
    "{%" "-"? space* name space* tagMarkup "-"? "%}"
    anyExceptStar<liquidRawTagClose<name>>
    "{%" "-"? space* "end" name space* "-"? "%}"
  liquidRawTagClose<name> =
    "{%" "-"? space* "end" name space* "-"? "%}"

  // In order for the grammar to "fallback" to the base case, this
  // rule must pass if and only if we support what we parse. This
  // implies that—since we don't support filters yet—we have a
  // positive lookahead on "-}}" or "}}" in the rule. We do this
  // because we'd otherwise positively match the following string
  // instead of falling back to the other rule:
  // {{ 'string' | some_filter }}
  liquidVariable = liquidExpression liquidFilter* space* &liquidStatementEnd
  liquidStatementEnd = ("-}}" | "}}" | "-%}" | "%}")

  liquidExpression =
    | liquidString
    | liquidNumber
    | liquidLiteral
    | liquidRange
    | liquidVariableLookup

  liquidString = liquidSingleQuotedString | liquidDoubleQuotedString
  liquidSingleQuotedString = "'" anyExceptStar<("'"| "%}" | "}}")> "'"
  liquidDoubleQuotedString = "\"" anyExceptStar<("\""| "%}" | "}}")> "\""

  liquidNumber = liquidFloat | liquidInteger
  liquidInteger = "-"? digit+
  liquidFloat = "-"? digit+ "." digit+

  liquidLiteral =
    ( "true"
    | "false"
    | "blank"
    | "empty"
    | "nil"
    | "null"
    ) ~identifierCharacter

  liquidRange =
    "(" space* liquidExpression space* ".." space* liquidExpression space* ")"

  liquidVariableLookup =
    | variableSegment lookup*
    | empty lookup+
  lookup =
    | indexLookup
    | dotLookup
  indexLookup = space* "[" space* liquidExpression space* "]"
  dotLookup = space* "." space* identifier

  liquidFilter = space* "|" space* identifier (space* ":" space* arguments)?

  arguments = nonemptyOrderedListOf<positionalArgument, namedArgument, argumentSeparator>
  argumentSeparator = space* "," space*
  argumentSeparatorOptionalComma = space* ","? space*
  positionalArgument = liquidExpression ~(space* ":")
  namedArgument = variableSegment space* ":" space* liquidExpression
  tagArguments = listOf<namedArgument, argumentSeparatorOptionalComma>

  variableSegment = (letter | "_") identifierCharacter*
  variableSegmentAsLookup = variableSegment
  identifier = variableSegment "?"?

  tagMarkup = anyExceptStar<("-%}"| "%}")>

  liquidTagName =
    letter (alnum | "_")*

  blockName =
    // Shopify blocks
    ( "form"
    | "paginate"
    // Base blocks
    | "capture"
    | "case"
    | "for"
    | "ifchanged"
    | "if"
    | "unless"
    | "tablerow"
    ) ~identifierCharacter
}

LiquidStatement <: Liquid {
  Node := listOf<LiquidStatement, statementSep> (space | newline)*

  // This is the big brains moment: we redefine space to exclude newlines.
  //
  // Which means that all our other Liquid rules can be reused
  // without modification(!)
  //
  // We don't need to maintain rules like this:
  // - liquidVariable<space>
  // - liquidExpression<space>
  // - variableLookup<space>
  // - ... long list of stuff that takes space as param
  // - liquidString<space>
  //
  // All we need is this little, VERY IMPORTANT, part right here that
  // make it so we can parse the same way in Liquid tags.
  //
  // I'm putting in this huge comment so that it's more obvious.
  space := " " | "\t"

  LiquidStatement =
    | liquidRawTag
    | liquidTagClose
    | liquidTagOpen
    | liquidTag
    | liquidInlineComment

  liquidTagOpenRule<name, markup>
    := (name ~identifierCharacter) space* markup &liquidStatementEnd

  liquidTagRule<name, markup>
    := (name ~identifierCharacter) space* markup &liquidStatementEnd

  liquidTagClose
    := "end" (blockName ~identifierCharacter) space* tagMarkup &liquidStatementEnd

  liquidRawTagImpl<name>
    := (name ~identifierCharacter) space* tagMarkup newline
      anyExceptStar<liquidRawTagClose<name>>
      "end" name space* &liquidStatementEnd

  liquidRawTagClose<name>
    := "end" name space* &liquidStatementEnd

  liquidInlineComment
    := "#" space? tagMarkup &liquidStatementEnd

  tagMarkup := anyExceptStar<liquidStatementEnd>

  // trailing whitespace, newline, + anything else before the next tag
  statementSep = space* newline (space | newline)*

  liquidStatementEnd := newline | end
}

LiquidHTML <: Liquid {
  Node := yamlFrontmatter? (HtmlNode | liquidNode | TextNode)*
  openControl += "<"

  yamlFrontmatter =
    "---" newline anyExceptStar<"---"> "---" newline

  HtmlNode =
    | HtmlComment
    | HtmlRawTag
    | HtmlVoidElement
    | HtmlSelfClosingElement
    | HtmlTagClose
    | HtmlTagOpen

  HtmlComment = "<!--" #(anyExceptStar<"-->"> "-->")

  // These are black holes, we'll ignore what's in them
  HtmlRawTag =
    | HtmlRawTagImpl<"script">
    | HtmlRawTagImpl<"style">
    | HtmlRawTagImpl<"svg">

  HtmlRawTagImpl<name> =
    #("<" name) AttrList ">" #(anyExceptStar<endTag<name>> endTag<name>)

  endTag<name> =
    "</" name space* ">"

  HtmlVoidElement =
    #("<" voidElementName &(space | "/" | ">")) AttrList "/"? ">"

  HtmlSelfClosingElement =
    #("<" tagNameOrLiquidDrop) AttrList "/>"

  HtmlTagOpen =
    #("<" tagNameOrLiquidDrop) AttrList ">"

  HtmlTagClose =
    #("</" tagNameOrLiquidDrop) ">"

  tagNameOrLiquidDrop =
    | tagName
    | liquidDrop

  tagName =
    letter (alnum | "-" | ":")*

  AttrList = Attr*

  Attr =
    liquidNode | AttrSingleQuoted | AttrDoubleQuoted | AttrUnquoted | attrEmpty

  attrEmpty = attrName

  AttrUnquoted = attrName "=" attrUnquotedValue
  AttrSingleQuoted = attrName "=" "'" #(attrSingleQuotedValue "'")
  AttrDoubleQuoted = attrName "=" "\"" #(attrDoubleQuotedValue "\"")

  // https://html.spec.whatwg.org/#attributes-2
  attrName = anyExceptPlus<(space | quotes | "=" | ">" | "/" | "{{" | "{%" | controls | noncharacters)>
  attrUnquotedValue = (attrUnquotedTextNode)*
  attrSingleQuotedValue = (liquidNode | attrSingleQuotedTextNode)*
  attrDoubleQuotedValue = (liquidNode | attrDoubleQuotedTextNode)*

  attrUnquotedTextNode = anyExceptPlus<(space | quotes | "=" | "<" | ">" | "${"`"}" | "{{" | "{%")>
  attrSingleQuotedTextNode = anyExceptPlus<("'" | "{{" | "{%")>
  attrDoubleQuotedTextNode = anyExceptPlus<("\""| "{{" | "{%")>

  quotes =  "'" | "\""

  // https://www.w3.org/TR/2011/WD-html-markup-20110113/syntax.html#void-element
  // Cheating a bit with by stretching it to the doctype
  voidElementName =
    ( caseInsensitive<"!doctype">
    | caseInsensitive<"area">
    | caseInsensitive<"base">
    | caseInsensitive<"br">
    | caseInsensitive<"col">
    | caseInsensitive<"command">
    | caseInsensitive<"embed">
    | caseInsensitive<"hr">
    | caseInsensitive<"img">
    | caseInsensitive<"input">
    | caseInsensitive<"keygen">
    | caseInsensitive<"link">
    | caseInsensitive<"meta">
    | caseInsensitive<"param">
    | caseInsensitive<"source">
    | caseInsensitive<"track">
    | caseInsensitive<"wbr">
    ) ~identifierCharacter
}
`;